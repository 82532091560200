import React, { FC } from 'react';
import { withPrefix } from 'gatsby';
import { Router } from '@reach/router';
import { Route } from 'components';

import BaseShipping from './index';

const Shipping: FC = () => (
  <Router basepath={withPrefix('/start/shipping')}>
    {/* {location.search.includes('tst3')
      ? undefined
      : Route('/payments', OldPayments)} */}
    {Route('/:code', BaseShipping)}
  </Router>
);

export default Shipping;
